<template>
  <div class='mt-4 lg:mt-12'>
    <div class='py-2 px-8' style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'>
      <h4 class='text-white font-semibold'>KOA Board of Directors</h4>
    </div>
    <div class='flex flex-row justify-between'>
      <div class='w-1/3 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/president.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Myung Ku Kim, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>President</div>
        </div>
      </div>
      <div class='w-1/3 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/chairman.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Jin Woo Lee, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>Chairman. Board of the Directors</div>
        </div>
      </div>
      <div class='w-1/3'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/secretary_and_treasurer.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Yun Rak Choi, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>Secretary and Treasurer</div>
        </div>
      </div>
    </div>
    <div class='mt-12 flex flex-row justify-between'>
      <div class='w-1/3 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/president_elect.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Ki Tack Kim, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>President-elect</div>
        </div>
      </div>
      <div class='w-1/3 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/chairman_elect.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Hong Geun Jung, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>Chairman-elect. Board of the Directors</div>
        </div>
      </div>
      <div class='w-1/3'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/secretary_and_treasurer_elect.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Joon Kyu Lee, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>Secretary and Treasurer-elect</div>
        </div>
      </div>
    </div>
    <div class='py-2 px-8 mt-12' style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'>
      <h4 class='text-white font-semibold'>Scientific Committee</h4>
    </div>
    <div class='flex flex-row justify-between'>
      <div class='w-1/2 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/scientific_committee_2.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Chang Hun Lee, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>HANYANG UNIV.</div>
        </div>
      </div>
      <div class='w-1/2'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/scientific_committee_3.png'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Min Jung, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>YONSEI UNIV.</div>
        </div>
      </div>
    </div>
    <div class='py-2 px-8 mt-12' style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'>
      <h4 class='text-white font-semibold'>International Relations Committee</h4>
    </div>
    <div class='flex flex-row justify-between'>
      <div class='w-full'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/international_relations_committee.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Taek Rim Yoon, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>CHONNAM NATIONAL UNIV.</div>
        </div>
      </div>
    </div>
    <div class='py-2 px-8 mt-12' style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'>
      <h4 class='text-white font-semibold'>Information and Communication Committee</h4>
    </div>
    <div class='flex flex-row justify-between'>
      <div class='w-1/2 border-r'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/information_and_communication_committee_1.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Jee Hyoung Kim, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>EOUL SACRED HEART GENERAL HOSPITAL</div>
        </div>
      </div>
      <div class='w-1/2'>
        <div class='border-b p-2 flex flex-row justify-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/organization/information_and_communication_committee_2.jpg'>
        </div>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Bom Soo Kim, MD, PhD</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>INHA UNIV.</div>
        </div>
      </div>
    </div>
    <div class='py-2 px-8 mt-12' style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);'>
      <h4 class='text-white font-semibold'>Secretariat</h4>
    </div>
    <div class='flex flex-row justify-between border-b'>
      <div class='w-1/3 border-r'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Su In Shin</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, Executive Director</div>
        </div>
      </div>
      <div class='w-1/3 border-r'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Joo Young Kim</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, General Manager</div>
        </div>
      </div>
      <div class='w-1/3'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Joanne Shin</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, International Manager</div>
        </div>
      </div>
    </div>
    <div class='flex flex-row justify-between border-b'>
      <div class='w-1/3 border-r'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Ji Yeon Lee</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, Assistant Manager</div>
        </div>
      </div>
      <div class='w-1/3 border-r'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Yu Jin Jang</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, Senior Assistant</div>
        </div>
      </div>
      <div class='w-1/3'>
        <div class='py-4 px-2'>
          <div class='text-center text-sm lg:text-base font-light'>Juri Kim</div>
          <div class='text-center text-xs lg:text-sm text-gray-500'>KOA, Administrative Assistant</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Organization',
}
</script>
